// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "../stylesheets/application.scss"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("DOMContentLoaded", (e) => {
  document.addEventListener("click", e => {
    let box = e.target.closest("div");
    if(box.classList.contains("box")){
      redirectToContent(box.dataset.id)
    }
    if(box.classList.contains("link")){
      copyToClipboard(box.dataset.url)
    }
  })
});

function redirectToContent(id){
  window.location.href = "/content?id="+id;
}

function copyToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
}
